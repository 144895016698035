<template>
  <section class="limited-list">
    <template v-for="(item, key) in list">
      <slot v-if="key < availableAmount" name="item" v-bind="item" />
    </template>
    <cta-button
      v-if="availableAmount < list.length"
      :title="translations.limitedList.loadMore"
      :styling-options="{ bg: 'transparent', bgHover: 'black-10', color: 'black-90' }"
      :action="
        () => {
          $emit('show-more');
          availableAmount += showMore;
        }
      "
      theme="transparent"
      class="limited-list__btn"
      size="large"
    />
  </section>
</template>
:
<script setup lang="ts">
import { ref, watch } from "vue";

import { translations } from "@/infrastructure/translations";

import CtaButton from "@/components/buttons/cta-button.vue";

const props = defineProps<{ aboveTheFold: number; showMore: number; list: any[] }>();
const emit = defineEmits<{ (a: "list-updated"): void; (a: "show-more"): void }>();

const availableAmount = ref(props.aboveTheFold);

watch(
  () => props.list.map((i) => i?.id).join(""),
  () => {
    availableAmount.value = props.aboveTheFold;
    emit("list-updated");
  },
);
</script>

<style lang="scss" scoped>
.limited-list {
  display: flex;
  flex-flow: column;
  &__btn {
    justify-self: center;
    margin: 6px auto;
  }
}
</style>
